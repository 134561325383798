@use 'src/assets/styles/_variables.scss' as *;

.fieldWrapper {
    label {
        width: 13px;
        min-width: 9rem;
        margin-top: 9px;
        color: #333333;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
    }
}

.modelHeader {
    font-size: 18px !important;
    color: $font-color-4 !important;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
}

.mandatory {
    font-size: 12px;
    color: #000;
    line-height: 15px;
    font-style: italic;
}

.required:after {
    margin: -0.2em 0 0 0.2em;
    content: '*';
    color: $required;
    margin-top: 2px !important;
}

.form {
    // border: 1px solid red;
    height: 17rem;
}