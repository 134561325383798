// @use 'src/assets/styles/_variables.scss' as *;



.mainTable tbody tr {
    border-radius: 4px 4px 5px 5px;
}

.alignRight {
    text-align: right;
}

.wrapper {
    margin-top: 20px;
}


.subcontractingRow {
    border-bottom: 1px solid rgb(240, 230, 230);
    height: 45px;
}