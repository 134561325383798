@use 'src/assets/styles/_variables.scss' as *;

.wrapperCreatesubContractor {
    display: flex;
    flex-direction: row;
    background-color: $background-color-1;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 1px;

    label {
        font-size: 13px;
        color: #5b5b5b;
    }
}

.tooltip {
    color: #9d4d4d !important;
    border-color: #9d4d4d !important;
    position: relative !important;
    // left: 25% !important;
    // right: 25% !important;
}

.pageName {
    font-size: 18px;
    color: $font-color-5;
    padding-top: 20px;
    margin-left: 5px;
    font-weight: bold;
}

.requiredWrapper {
    width: 100% !important;
}

.verificationWrapper {
    margin: 0 0.8em 1em !important;

    label {
        padding-top: 8px;
        width: 10rem;
        font-weight: 700;
    }

    input {
        width: 25rem !important;
    }
}

.lookupBtn {
    margin-right: 8px !important;
    margin-left: 5px !important;
}

.autofillBtn {
    width: 90px !important;
}

.createBtn {
    button {
        height: 38px;
        white-space: nowrap !important;
    }
}

@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    .createBtn {
        padding-left: 15px !important;

        button {
            height: 38px;
        }
    }

    .fieldname {
        display: inline !important;
    }
}

.fieldname {

    //display: inline-flex;
    label {
        display: inline-block !important;
        font-size: 13px;
        width: 16rem;
    }
}

.required:after {
    margin: -0.2em 0 0 0.2em;
    content: '*';
    color: $required;
}

.topButtons {
    button {
        margin-left: 8px !important;
        //background-color: $white !important;
        //border: 1px solid $border-color-1 !important;
        //color: $background-color-2 !important;
    }
}

.instruction {
    .instructionHeader {
        color: $font-color-6;
        padding-bottom: 1em;
    }

    p {
        line-height: 24px;
    }
}

.verificationNo {
    label {
        margin-right: 6rem;
        width: 10rem;
    }

    input {
        width: 30rem !important;
    }
}

.fieldMargin {
    margin-right: 5rem !important;
}

@media screen and (max-width: 800px) {
    .fieldMargin {
        margin-right: 0 !important;
    }

    :global {
        .ui.form .fields .wide.field {
            margin-bottom: 1rem;
        }
    }
}

i {
    background: none;

    //color: #4d4d4d;
    &::before {
        font-size: 13px;
        position: relative;
        top: 0;
    }
}

.datepicker {

    //padding: 0 15px !important;
    :global {
        .field:first-of-type {
            display: block !important;
            margin: 0 !important;
        }

        i.close {
            &::before {
                top: 50% !important;
                color: initial !important;
                font-weight: normal;
            }
        }
    }
}

.disableDatepicker {

    // padding: 0 15px !important;
    :global {
        .field:first-of-type {
            display: block !important;
            margin: 0 !important;
        }
    }

    input {
        background: #e4e0e0 !important;
    }

    i.close {
        display: none !important;
    }
}

.required:after {
    margin: -0.2em 0 0 0.2em;
    content: '*';
    color: $required;
}

.createmandatory {
    width: 100%;
    font-style: italic;
    text-align: right;
    font-size: 12px;
    color: $font-color-4;
    font-family: italic;
    font-weight: bold;
}

.transitionMsgCreate {
    position: absolute !important;
    z-index: 1;
    width: fit-content;
    margin: 0 auto !important;
    top: 70px;
    left: 0;
    right: 10px;
    padding: 7px !important;
    //color: #1E561F !important;
    font-weight: 700;
    font-size: 13px !important;
    line-height: 17px !important;

    i {

        //background: transparent !important;
        &::before {
            position: relative;
            top: 7px;
            font-size: 16px;
            color: #912d2b;
        }
    }

    span {

        //padding-left: 30px;
        span {
            position: relative;
            top: 7px;
            right: 30px;
            left: 3px;
            display: inline-block;
            width: fit-content;
            padding-right: 29px;
        }
    }
}

.datePickerField {

    //padding: 0 15px !important;
    >div {
        width: 100%;
    }

    i.close {
        color: initial !important;
    }

    .disable {
        i.close {
            display: none;
        }
    }
}

.leftRightPadding {
    // padding: 0 10px !important;
    padding-right: 15px !important;
}

.leftRightPadding1 {
    padding-left: 7.5px !important;
    padding-right: 15px !important;
}