@use 'src/assets/styles/_variables.scss' as *;

.required:after {
    margin: -0.2em 0 0 0.2em;
    content: '*';
    color: $required;
}

.datepicker {
    width: 100%;

    :global {
        .field:first-of-type {
            display: block !important;
            margin: 0 !important;
        }

        i.close {
            &::before {
                top: 50% !important;
                color: initial !important;
                font-weight: normal;
            }
        }
    }
}

.datepickerParent {
    :global {
        .field {
            width: 100% !important;
            display: inline !important;
        }
    }
}