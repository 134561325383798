@use 'src/assets/styles/_variables.scss' as *;

.wrapperContractorInfo {
    //height: calc(100vh - 144px);
    display: flex;
    flex-direction: row;
    //background-color: #f7f7f7;
    padding: 0 20px;
    padding-bottom: 1px;

    h1 {}

    h1.header {
        padding-top: 4em !important;
        font-size: 4em;

    }

    i {
        background: none;

        &::before {
            font-size: 13px;
            position: relative;
            top: 0;
        }
    }
}

.pageName {
    font-size: 18px;
    color: $font-color-5;
    padding-top: 20px;
    margin-left: 5px;
    font-weight: bold;
}

.buildicon {
    background: #2185d0 !important;
    opacity: 1;
    height: 44px !important;
    width: 44px !important;

    &::before {
        font-size: 17px !important;
        top: 11px !important;
        position: relative !important;
    }
}

.ownEdit {
    &::before {
        color: white !important;
    }
}

.inputFieldLabel {
    width: 222px !important;
    // margin-right: 4rem !important;
    height: 38px;
    padding: 12px 0px 0px 10px !important;

    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 17px;
    color: #000000 !important;

    input {
        width: 222px !important;
        // margin-right: 4rem !important;
        height: 38px;
        padding: 12px !important;
        color: #000000 !important;
        background-color: #dfdfdf !important;
    }
}

.nameLabelField {
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0px;
    color: #5b5b5b;
    opacity: 1;
    min-width: 126px !important;
}

.contractorinfo {
    box-shadow: 0px 1px 2px #22242626 !important;

    table {
        th {
            padding: 0.62857143em 0.78571429em !important;
        }
    }
}

.top {
    position: relative;
    top: 10px;
    color: $font-color-4;
    font: normal normal bold 16px/40px;
    letter-spacing: 0px;
    left: 8px;
}

.comdetail {
    display: flex;
    flex: 1;

    >div {
        width: 33%;

        div {
            padding-bottom: 15px;
        }
    }

    label {
        font: normal normal bold 13px/16px;
        letter-spacing: 0px;
        color: #00000099;
    }

    span {
        font: normal normal bold 14px/17px;
        letter-spacing: 0px;
        color: $font-color-4;
        text-transform: lowercase;
        padding-left: 5px;
    }
}

.rowLowPadding {
    padding-top: 0.5em !important;
}

.prime-contractor-info table tr td {
    border: none !important;
}

.prime-contractor-info table tr {
    border: none !important;
}

.contractorHeader {
    color: #3c3c3c !important;
    font-size: 16px !important;
    line-height: 19px !important;
    font-weight: 700 !important;
}

.transitionMsg {
    position: absolute !important;
    z-index: 1;
    width: 29rem !important;
    margin: 0 auto !important;
    top: 70px;
    left: 0;
    right: 0;
    padding: 7px !important;
    color: #1E561F !important;
    font-weight: 700;
    font-size: 14px !important;
    line-height: 17px !important;

    i {

        //background: transparent !important;
        &::before {
            position: relative;
            top: 7px;
            font-size: 16px;
        }
    }

    span {
        padding-left: 5px;

        span {
            position: relative;
            top: 7px;
        }
    }
}

.companyNameNowrap {
    text-overflow: ellipsis;
    width: 13rem;
    height: 38px;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
}

.summarytooltip {
    width: 10rem !important;
    background-color: #535353 !important;

    &::before {
        background-color: #535353 !important;
    }
}

.fieldWrapper {
    display: flex !important;

    label {
        width: 9rem;
        min-width: 9rem;
        margin-top: 9px;
        color: #333333;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;

    }
}

.flexWidth {
    width: 89%;
}

.colwrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.colwrapper {}

@media (max-width: 1281px) {
    .nameLabelField {
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
        min-width: 100px !important;
    }

    .flexWidth {
        width: 98%;
    }

    .companyNameNowrap {
        width: 13rem;
    }
}

@media (max-width: 991px) {
    .companyNameNowrap {
        width: 8rem;
    }

}

@media (max-width: 556px) {
    .colwrapper {
        display: grid;

        label {
            padding-bottom: 5px;
        }

        //justify-content: space-between;
        //align-items: center;
    }
}

@media (max-width: 1056px) {
    .fieldWrapper {
        padding-bottom: 20px;
    }

    .rowWrapper {
        flex-direction: column !important;
        padding-bottom: 10px;
    }
}

.contactUserCls {
    display: flex;
    flex-direction: row;
    //background-color: #f7f7f7;
    padding: 0 20px !important;
    padding-bottom: 1px;
    padding-top: 14px !important;
}

.gridWapper {
    position: relative;
}

.editButton {
    position: absolute;
    right: 0;
    margin-right: 7rem !important;
    padding: 10px 15px !important;

    i {
        color: #ffffff !important;

        &::before {
            font-size: 13px;
            position: relative;
            top: -1px;
        }
    }
}

.exportButton {
    position: absolute;
    right: 0;
    padding: 10px 15px !important;

    i {
        color: #1194eb !important;

        &::before {
            font-size: 13px;
            position: relative;
            top: -1px;
        }
    }
}

.exportButtonPrime {
    position: absolute;
    right: 15px;
    padding: 10px 15px !important;

    i {
        color: #1194eb !important;

        &::before {
            font-size: 13px;
            position: relative;
            top: -1px;
        }
    }
}