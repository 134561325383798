@use 'src/assets/styles/_variables.scss' as *;

.wrapper {
    display: flex;
    flex-direction: row;
    background-color: $background-color-1;
    padding-left: 20px;
    padding-right: 20px;

    .subContractorWrapperView {
        display: flex;
        flex-direction: row;
        background-color: $background-color-1;
        padding-left: 20px;
        padding-right: 20px;

        h1.header {
            padding-top: 4em !important;
            font-size: 4em;
        }

        i {
            background: none;

            &::before {
                font-size: 13px;
                position: relative;
                top: 0;
            }
        }

        .input {
            width: 8em !important;
        }
    }

    .input {
        width: 8em !important;
    }

    label {
        color: #333333de !important;
        font-size: 13px !important;
        font-weight: 700;
        line-height: 16px !important;
    }
}

.fieldname {
    label {
        display: inline-block !important;
        width: 125px;
        color: #00000099 !important;
        font-size: 13px/16px;
    }

    >div {
        width: auto !important;
    }
}

.required:after {
    margin: -0.2em 0 0 0.2em;
    content: '*';
    color: $required;
}

.pageName {
    font-size: 18px;
    color: $font-color-5;
    padding-top: 20px;
    margin-left: 5px;
    font-weight: bold;
}

.buildicon {
    background: #2185d0 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 44px !important;
    width: 44px !important;

    &::before {
        font-size: 17px;
        top: 11px;
        position: relative;
    }
}

.contractorinfo {
    box-shadow: 0px 1px 2px #22242626 !important;

    table {
        th {
            padding: 0.62857143em 0.78571429em !important;
        }
    }
}

.top {
    position: relative;
    top: 10px;
    color: $font-color-4;
    font: normal normal bold 16px/40px;
    letter-spacing: 0px;
    left: 8px;
}

.comdetail {
    display: flex;
    flex: 1;

    >div {
        width: 33%;

        div {
            padding-bottom: 15px;
        }
    }

    label {
        font: normal normal bold 13px/16px;
        letter-spacing: 0px;
        color: #00000099;
    }

    span {
        font: normal normal bold 14px/17px;
        letter-spacing: 0px;
        color: $font-color-4;
        text-transform: lowercase;
        padding-left: 5px;
    }
}

.rowLowPadding {
    padding-top: 0.5em !important;
}

.modelHeader {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    //padding: 0.8rem 1rem !important;
}

.mandatory {
    font-size: 12px;
    color: $font-color-4;
    font-style: italic;
}

.inputDisabled {
    opacity: 1 !important;

    label {
        display: inline-block !important;
        width: 125px;
        color: #00000099 !important;
        font-size: 13px/16px;
        opacity: 1 !important;
    }

    >div {
        width: auto !important;
    }

    input {
        background: #e7e3e3 !important;
    }
}

.activeInactive {
    padding-top: 32px !important;

    label {
        color: #5b5b5b !important;
        font-size: 13px !important;
        line-height: 16px;
        font-weight: 700;
    }
}

.form {
    // border: 1px solid red;
    // height: 18rem;
    height: 16rem;
}

.formField {
    // border: 1px solid red;
    height: 3rem;

    div {
        width: 100% !important;
    }
}

.column {
    padding: 0px 13px !important;
}

.reasonColumn {
    padding: 0px 13px !important;
    padding-right: 7px !important;
}

.tooltip {
    color: #9d4d4d !important;
    border-color: #9d4d4d !important;
    position: absolute !important;
    left: 25% !important;
    right: 25% !important;
}