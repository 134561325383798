@use 'src/assets/styles/_variables.scss' as *;

.wrapperPrimeDetails {
    display: flex;
    flex-direction: row;
    // padding-left: 20px;
    // padding-right: 20px;

    .segment {
        border: 0 !important;
    }
}

table {
    tr {
        th {
            border-left: none !important;
        }

        td {
            border-left: none !important;
        }
    }

    i {
        background: transparent;
    }
}

.buildicon {
    background: #2185d0 !important;
    opacity: 1;
    height: 44px !important;
    width: 44px !important;

    &::before {
        font-size: 17px !important;
        top: 11px !important;
        position: relative !important;
    }
}

.top {
    position: relative;
    top: 10px;
    color: $font-color-4;
    font: normal normal bold 16px/40px;
    letter-spacing: 0px;
    left: 8px;

    span {
        border-radius: 4px;
        height: 28px;
        width: fit-content;
        padding: 5px 8px;
        margin-left: 10px;
        font-size: 13px;
        line-height: 24px;
        font-weight: bold;
        position: relative;
        top: -2px;
    }

    .active {
        background: #c1f2f89d !important;
    }

    .inactive {
        background: #f7cfcf !important;
    }
}

.dateAdded {
    display: flex;
    flex-direction: row;
    color: #333333de;
    font-weight: bold;
    font-size: 13px;
    line-height: 22px;
    text-align: right !important;

    span {
        display: block;
    }
}

.inputSection {
    input {
        margin-left: 15px !important;
        width: 18rem !important;
    }
}

.inputSectionVon {
    input {
        margin-left: 15px !important;
        // width: 18rem !important;
    }
}

div.inputSectionSelect {
    margin-left: 10px !important;
    // width: 18rem  !important;
}

.subcontractorName {
    width: 20rem !important;
}

.von {
    width: 15rem !important;
}

.status {
    width: 9rem !important;
    margin-left: 3px;
}

.reason {
    width: 15rem !important;
}

.action {
    width: 10rem !important;
}

.rowData {
    font-size: 14px !important;
    line-height: 24px;
    color: #000 !important;
}

.required:after {
    margin: -0.2em 0 0 0.2em;
    content: '*';
    color: $required;
}

.activeContract {
    width: 12px;
    height: 12px;
    background: #2c662d;
    border-radius: 100%;
    display: inline-block;
    margin-right: 3px;
}

.inactiveContract {
    width: 12px;
    height: 12px;
    background: #9f3a38;
    border-radius: 100%;
    display: inline-block;
    margin-right: 3px;
}

.inputLabel {
    margin-left: 20px !important;
}

@media (max-width: 1500px) {
    .inputSection {
        input {
            margin-left: 8px !important;
            width: 15rem !important;
        }
    }

    div.inputSectionSelect {
        min-width: 10rem !important;
        margin-left: 8px !important;
        width: 10rem !important;
    }

    .inputSectionVon {
        input {
            margin-left: 8px !important;
            width: 10rem !important;
        }
    }

    .inputLabel {
        margin-left: 15px !important;
    }
}

@media (max-width: 1280px) {
    .inputSection {
        input {
            margin-left: 5px !important;
        }
    }

    div.inputSectionSelect {
        min-width: 8rem !important;
        margin-left: 5px !important;
        width: 8rem !important;
    }

    .inputSectionVon {
        input {
            margin-left: 5px !important;
            width: 7rem !important;
        }
    }

    .inputLabel {
        margin-left: 10px !important;
    }
}