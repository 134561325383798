@use 'src/assets/styles/_variables.scss' as *;

.disableFunctionalityText {
    text-align: center;
    color: red;
    font-size: 18px;
    // width: 100%;
}

.disableFunctionality {
    width: '100%'
}