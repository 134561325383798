@use 'src/assets/styles/_variables.scss' as *;

.form {
    :global {
        .field {
            width: 100%;
        }
    }
}

.custom-table {
    border: none;
}

// .table1 tr:last-child {
//     background-color: #ffa50016;
//     font-weight: bold !important;
//     color: #000000dd !important;
// }

tr td button i {
    color: grey !important;
}

.passwordField {
    width: 100%;
}

.btnSignIn {
    width: 100%;
    background: #009bda !important;
    border-radius: 5px;
    color: $white !important;
    height: 54px;
    opacity: 1;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0px;
    padding-left: 10px;
}

.signDesc {
    text-align: left;
    font: normal normal normal 15px/18px;
    letter-spacing: 0px;
    color: $font-color-2;
    opacity: 1;
}

.loginrow {
    padding-bottom: 0px !important;
    padding-top: 0px !important;

    label {
        font-weight: bold !important;
        font-size: 13px !important;
    }

}

.loginContent {
    margin: auto;
    text-align: center;
    width: 92%;
    position: relative;
    top: 40%;
    font: normal normal normal 18px/26px;

    span {
        font-size: 25px;
        line-height: 26px;
    }
}

.header {
    color: $white !important;
    font-size: 3rem !important;
    line-height: 26px !important;
    padding: 0 0 23px 0 !important;
}

.loginImg {
    display: block;
    color: $white;
    width: 41% !important;
    background: #F8FFFF;
    border: 1px solid #A9D5DE;

    .content {
        color: $white;
    }
}

.fieldContainer {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    text-align: left;

    label {
        color: #333333DE !important;
        text-align: left;
        width: 15rem;
        font-weight: bold;
        font-size: 13px;
        line-height: 22px;
    }
}

.selectFieldContainer {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    text-align: left;

    label {
        color: #333333DE !important;
        text-align: left;
        width: 15rem;
        font-weight: bold;
        font-size: 13px;
        line-height: 22px;
    }

}

.selectbox {
    width: 14rem !important;
}


.btnContainer {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.fieldContainerPassword {
    position: relative;
    text-align: left;
}

.forgotpasswordContainer {
    padding-top: 15px;

    a {
        font-size: 14px;
        line-height: 17px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #001c71cc;
        opacity: 1;
    }
}

.signIn {
    text-align: left !important;
    width: 100% !important;
    margin-bottom: 9px !important;
    padding: 0 20px !important;
    background-color: #fff;
    border-radius: 5px;
}

.eyeicon {
    color: lightgray;
    position: absolute;
    float: right;
    top: 35px;
    right: 4px;
    cursor: pointer;
}

.signinFooterContainer {
    width: 90%;
    margin: 0 auto;
    padding: 10px 0 0 0;

    span {
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0px;
        color: #7F7878;
    }
}

.loginTitle {
    font-size: 35px;
    font-weight: bold;
    line-height: 42px;
    letter-spacing: 0px;
    color: #011c71;
    padding-bottom: 18px;
}

.emploginBtn {
    margin-top: 8px !important;
    color: #fff !important;
    height: 54px !important;
    background: #011c71 !important;
    width: 100%;

    span {
        position: relative;
        top: 8px;
    }
}

.buildicon {
    opacity: 1;
    position: relative;
    top: -8px;

    &::before {
        color: #fff;
        font-size: 17px !important;
        top: 11px !important;
        position: relative !important;
    }
}

.loginFooter {
    //position: relative;
    top: 63px;
}

.devider {
    margin-top: 25px !important;

    span {
        color: #333333dd;
        font-size: 14px;
        line-height: 17px;
        position: relative;
        top: -8px;
        background: #fff;
    }
}

.footerImg {
    display: inline-flex;
    flex-direction: row;
    padding: 25px 0 10px 0;

    img {
        margin: 0 auto;
    }
}

.toastMessage {
    i.close {
        &::before {
            color: #912d2b !important;
        }
    }
}



.breadcrumb {
    background: #011C71;
    color: #fff;
    opacity: 0.71;
    height: 52px;

    span {
        position: relative;
        top: 15px;
        left: 22px;

        a {
            color: #fff;
            text-decoration: underline;
        }
    }
}

.resetInner {
    width: 97%;
    margin: 12px auto !important;
}

.resetPasswordHeader {
    span {
        padding-bottom: 20px;
        display: block;
        color: #000000A5;
        font-size: 14px;
    }
}

.checkbox {
    label {
        width: 100% !important;
    }
}