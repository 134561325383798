@use 'src/assets/styles/_variables.scss' as *;

.contractorViewWrapper {
    display: flex;
    flex-direction: row;
    background-color: $background-color-1;
    padding-left: 20px;
    padding-right: 20px;

    h1 {}

    i {
        background: none;

        &::before {
            font-size: 13px;
            position: relative;
            top: 0;
        }
    }

    .input {
        width: 8em !important;
    }
}

.subContractorViewTitle {
    font-weight: bold;
    font-size: 16px !important;
    line-height: 28px !important;
    color: #3c3c3c;
    padding-bottom: 15px !important;
}

.required:after {
    margin: -0.2em 0 0 0.2em;
    content: '*';
    color: $required;
}

.pageName {
    font-size: 18px;
    color: $font-color-5;
    padding-top: 20px;
    margin-left: 5px;
    font-weight: bold;
}

.modelHeader {
    font-size: 18px !important;
    color: $font-color-4 !important;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
}

.mandatory {
    font-size: 12px;
    color: $font-color-4;
}

.inputDisabled {
    opacity: 1 !important;

    label {
        display: inline-block !important;
        width: 135px;
        color: #333333 !important;
        font-size: 13px;
        line-height: 16px;
        opacity: 1 !important;
    }

    >div {
        width: auto !important;
    }

    input {
        background: #e7e3e3 !important;
    }
}

.subcontractorViewModel {
    width: 900px !important;

    input {
        width: 16rem !important;
    }

    label {}
}

.subconColWrapper {
    padding-bottom: 0.3rem !important;
    padding-top: 0.3rem !important;

    .fieldname {
        label {
            display: inline-block !important;
            width: 135px;
            color: #333 !important;
            font-size: 13px;
            line-height: 16px;
        }

        >div {
            width: auto !important;
        }

        // input {
        //     text-transform: capitalize;
        // }
    }
}

.genderCapital {
    input {
        text-transform: capitalize;
    }

}

.subcontractorViewForm {
    display: inherit;

    label {
        display: inline-block !important;
        width: 135px;
        color: #333 !important;
        font-size: 13px;
        line-height: 16px;
    }

    >div {
        width: auto !important;
    }
}

.modelPageHeader {
    font-size: 18px !important;
    color: $font-color-4 !important;
    line-height: 40px;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;

    padding-bottom: 10px !important;
    padding-top: 10px !important;
}