@use 'src/assets/styles/_variables.scss' as *;

.fieldWrapper {
  label {
    width: 13px;
    min-width: 9rem;
    margin-top: 9px;
    color: #333333;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;

  }
}

.required:after {
  margin: -0.2em 0 0 0.2em;
  content: '*';
  color: $required;
  margin-top: 2px !important;
}