@use 'src/assets/styles/_variables.scss' as *;

:root {
    --border-color: #dedede;
}

.wrapper {
    // height: calc(100vh - 144px);
    display: flex;
    flex-direction: row;
    //background-color: #f7f7f7;
    padding-left: 20px;
    padding-right: 20px;

    h1.header {
        padding-top: 4em !important;
        font-size: 4em;
    }

    table {
        tr {
            th {
                border-left: none !important;
            }

            td {
                border-left: none !important;
            }
        }
    }
}

.pageName {
    font-size: 18px;
    color: $font-color-5;
    padding-top: 20px;
    margin-left: 5px;
    font-weight: bold;
}

.activeContract {
    width: 12px;
    height: 12px;
    background: #2c662d;
    border-radius: 100%;
    display: inline-block;
    margin-right: 3px;
}

.inactiveContract {
    width: 12px;
    height: 12px;
    background: #9f3a38;
    border-radius: 100%;
    display: inline-block;
    margin-right: 3px;
}

.modalContentWrapper {
    display: flex !important;
    justify-content: center;
    margin: 10px 0px !important;
}

.modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: 1px solid var(--border-color);
}

.modalRowHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0px;
    background-color: #f9fafb !important;
}

.modalRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.modalCol {
    flex-grow: 1;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    border-bottom: 1px solid var(--border-color);
}

.modalColHeader {
    flex-grow: 1;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    border-bottom: 1px solid var(--border-color);
    background-color: #f9fafb !important;
    font-weight: 600;
}

.red {
    color: #d01919 !important;
}

i {
    background: none;
    color: #565656;

    &::before {
        font-size: 13px;
        position: relative;
        top: 0;
    }
}

.modalHeader {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px !important;
}

.tooltip {
    color: #9d4d4d !important;
    border-color: #9d4d4d !important;
    position: absolute !important;
    left: 25% !important;
    right: 25% !important;
}

.tooltipErrorDesc {
    color: #9d4d4d !important;
    border-color: #9d4d4d !important;
    position: absolute !important;
    left: 25% !important;
    right: 25% !important;
    // position: absolute !important;
    transform: translate(-50%, 8%);
    margin: 0 auto;
    // left: 50% !important;
    margin-top: 5px !important;
}

.notHeader {
    display: inline-block;
    margin: 0 0 0 !important;
    line-height: 22px !important;
    font-size: 18px;
}

.notIcon {
    background: rgba(167, 0, 181, 1) 0% 0% no-repeat padding-box;
    top: 109px;
    left: 21px;
    width: 22px;
    height: 26px;
}

.inDays {
    padding: 0.5em;
    background: rgba(247, 225, 207, 1) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    font-weight: bolder;
}

$error-color: #9f3a38;
$success-color: #568555;

.toastError {
    i::before {
        font-size: larger !important;
        color: $error-color !important;
    }
}

.toastSuccess {
    i::before {
        font-size: larger !important;
        color: $success-color !important;
    }
}

.pageTitle {
    font-size: 18px !important;
    color: rgba(38, 38, 38, 1) !important;
    font-weight: bold !important;
}

.contractsTable {
    table {
        thead {

            // font: Heavy 13px #000000cc !important;
            tr {
                td {
                    font-size: 13px !important;
                    color: #000000cc !important;
                }
            }

            th {
                color: rgba(0, 0, 0, 0.8) !important;
            }
        }
    }
}

.descriptionWidth {
    width: 15rem;
}